import React, {useEffect, useRef, useState} from "react";
import {
    PageHeader,
    Row,
    Col,
    Card,
    Input,
    Button,
    List,
    Spin,
    Empty,
    notification,
    Modal
} from "antd";
import gql from 'graphql-tag';
import {useMutation, ApolloProvider} from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';
import {Scoped} from 'kremling';

const {REACT_APP_BATCH_REBIN_API} = process.env;

const FIND_INVENTORIES = gql`
    query FindInventories($identity: String!) {
        inventories(identity: $identity) {
            id
            itemId
            inventoryId
            itemNumber
            shelf
            description
            condition
            mfgr
            serialNumber
            status
            serviceOrder
            quantity
            dateReceived
            dateBooked
            dateAudited
            warehouse
        }
    }
`;

const CREATE_BATCH = gql`
    mutation CREATE_BATCH($inventoriesForMovement: [InventoryForMovement!]!, $shelf: String!, $comment: String) {
        createBatch(inventoriesForMovement: $inventoriesForMovement, shelf: $shelf, comment: $comment) {
            id
            itemId
            inventoryId
            itemNumber
            shelf
            description
            condition
            mfgr
            serialNumber
            status
            serviceOrder
            quantity
            dateReceived
            dateBooked
            dateAudited
            warehouse
        }
    }
`;

const {Search} = Input;

const isNotBlank = (str) => str && str.trim().length;
const isBlank = (str) => !isNotBlank(str);

const INV_WITH_SERIAL = "INV_WITH_SERIAL";
const INV_WITHOUT_SERIAL = "INV_WITHOUT_SERIAL";

const groupInventoriesToItem = (inventories) => {
    if (!inventories || !inventories.length) {
        return null;
    }

    const itemId = inventories[0].itemId;
    const itemNumber = inventories[0].itemNumber;
    const warehouse = inventories[0].warehouse;
    const mfgr = inventories[0].mfgr;
    const description = inventories[0].description;

    const invWithoutSerialNumbers = inventories.filter(inv => isBlank(inv.serialNumber));
    let invsWithoutSerialGroupByShelf = {};

    invWithoutSerialNumbers.forEach(invWSN => {
        if (!invsWithoutSerialGroupByShelf[invWSN.shelf]) {
            invsWithoutSerialGroupByShelf[invWSN.shelf] = [invWSN];
        } else {
            invsWithoutSerialGroupByShelf[invWSN.shelf] = [...invsWithoutSerialGroupByShelf[invWSN.shelf], invWSN];
        }
    });

    return {
        itemId,
        itemNumber,
        warehouse,
        mfgr,
        description,
        groups: [
            ...inventories.filter(inv => isNotBlank(inv.serialNumber)).map(inv => ({
                ...inv,
                key: inv.serialNumber,
                type: INV_WITH_SERIAL,
            })),
            ...Object.keys(invsWithoutSerialGroupByShelf).map(shelf => ({
                key: shelf + itemNumber,
                shelf,
                itemId,
                itemNumber,
                quantity: 1,
                inventories: invsWithoutSerialGroupByShelf[shelf],
                type: INV_WITHOUT_SERIAL
            }))
        ],
    };
};

const Inventory = ({group}) => {
    if (group.type === INV_WITH_SERIAL) {
        if (isNotBlank(group.shelf) && group.shelf !== "null") {
            return `Inventory with SN: ${group.serialNumber} is found on the shelf SN: ${group.shelf}`;
        } else {
            return `Inventory with SN: ${group.serialNumber} with no Location`;
        }
    } else {
        if (isNotBlank(group.shelf) && group.shelf !== "null") {
            return `${group.inventories.length} ${group.inventories.length === 1 ? 'inventory is' : 'inventories were'} found on the shelf SN: ${group.shelf}`;
        } else {
            return `${group.inventories.length} ${group.inventories.length === 1 ? 'inventory' : 'inventories'} with no Location`;
        }
    }
};

const Item = ({item, spinning = false, onAdd, groupsInBatch}) => (
    <Spin spinning={spinning}>
        {item ? (
            <div>
                <div className="text-bold mb-1">
                    Item id: {item.itemId} <br/>
                    Item number: {item.itemNumber} <br/>
                    Warehouse: {item.warehouse ? item.warehouse : "-"} <br/>
                    MFGR: {item.mfgr ? item.mfgr : "-"} <br/>
                    Description: {item.description ? item.description : "-"} <br/>
                </div>
                <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={item.groups}
                    renderItem={group => (
                        <List.Item
                            extra={
                                (
                                    <Button
                                        icon="plus"
                                        title="Add"
                                        type="primary"
                                        disabled={groupsInBatch.find(gib => group.key === gib.key && (!group.inventories || gib.quantity === group.inventories.length))}
                                        onClick={onAdd(group)}
                                    />
                                )
                            }>
                            <Inventory group={group}/>
                        </List.Item>
                    )}
                />
            </div>
        ) : <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>}
    </Spin>
);

const ModalContent = ({selectedGroup, onQuantityChanged, onPressEnter}) => {
    const quantityRef = useRef(null);
    useEffect(() => {
        if (selectedGroup) {
            quantityRef.current.focus();
        }
    }, [selectedGroup]);
    if (!selectedGroup) {
        return null;
    }
    return (
        <span>
            Move
            <Input
                ref={quantityRef}
                className="ml-05 mr-05"
                style={{width: 60}}
                title={`Type number of items you want to move. Max (${selectedGroup.inventories.length})`}
                value={selectedGroup.quantity}
                onChange={onQuantityChanged}
                onPressEnter={onPressEnter}
            />
            items out of {selectedGroup.inventories.length} available.
        </span>
    );
};

const ItemCard = ({onAdd, groupsInBatch, client, item, setItem}) => {
    const identityRef = useRef();
    const [loading, setLoading] = useState(false);
    let [selectedGroup, setSelectedGroup] = useState(null);
    let [identity, setIdentity] = useState('');

    useEffect(() => {
        identityRef.current.focus()
    }, []);

    const onSearch = async (identity) => {
        setLoading(true);
        try {
            const {data: {inventories = []}} = await client.query({
                query: FIND_INVENTORIES,
                variables: {identity}
            });
            if (inventories && inventories.length === 1) {
                const inventory = inventories[0];
                if (isNotBlank(inventory.serialNumber)) {
                    onAdd({...inventory, key: inventory.serialNumber, type: INV_WITH_SERIAL});
                }
            }
            setItem(groupInventoriesToItem(inventories));
        } catch (e) {
            notification.error({message: "No inventories found"});
            setItem(null);
        } finally {
            setIdentity("");
            setLoading(false);
            identityRef.current.focus();
        }
    };


    const onGroupAdd = (group) => () => {
        if (group.type === INV_WITH_SERIAL) {
            onAdd(group);
        } else {
            setSelectedGroup(group);
        }
    };

    const onQuantityChanged = ({target: {value}}) => {
        const str = value.replace(/\D/g, '');
        let quantity;
        if (isBlank(str)) {
            quantity = 1;
        } else {
            quantity = Number.parseInt(str);
        }
        const maxLength = selectedGroup.inventories.length;
        if (quantity <= maxLength) {
            setSelectedGroup({...selectedGroup, quantity});
        } else {
            quantity = Math.floor(quantity % (10 * maxLength.toString().length));
            setSelectedGroup({...selectedGroup, quantity: quantity > maxLength ? maxLength : quantity});
        }

    };

    const onOk = (e) => {
        e.preventDefault();
        onAdd(selectedGroup);
        setSelectedGroup(null);
    };

    return (
        <Card
            title={
                <Input
                    ref={identityRef}
                    placeholder="SN/IN or Shelf Number"
                    size="large"
                    value={identity}
                    onChange={(e) => setIdentity(e.target.value)}
                    onKeyPress={event => event.key === 'Enter' ? onSearch(identity) : null}
                    onPaste={(e) => {
                        e.preventDefault();
                        const identity = e.clipboardData.getData('Text');
                        if (isNotBlank(identity)) {
                            setIdentity(identity);
                            onSearch(identity);
                        }
                    }}
                    addonAfter={<Button
                        type="button"
                        className="ant-btn ant-input-search-button ant-btn-primary ant-btn-lg"
                        disabled={loading || isBlank(identity)}
                        onClick={() => onSearch(identity)}
                        icon="search"
                    />}
                    disabled={loading}
                    loading={loading}
                />
            }
        >
            <Item
                item={item}
                spinning={loading}
                onAddDisabled={loading}
                onAdd={onGroupAdd}
                groupsInBatch={groupsInBatch}
            />
            <Modal
                visible={selectedGroup !== null}
                onOk={onOk}
                onCancel={() => setSelectedGroup(null)}
            >
                <ModalContent
                    selectedGroup={selectedGroup}
                    onQuantityChanged={onQuantityChanged}
                    onPressEnter={onOk}
                />
            </Modal>
        </Card>
    )
};

const BatchGroup = ({group}) => {
    if (isNotBlank(group.serialNumber)) {
        if (isNotBlank(group.shelf) && group.shelf !== "null") {
            return `IN: ${group.itemNumber}, SN: ${group.serialNumber} from the shelf SN: ${group.shelf}`
        } else {
            return `IN: ${group.itemNumber}, SN: ${group.serialNumber} from the unknown Location`
        }
    } else {
        if (isNotBlank(group.shelf) && group.shelf !== "null") {
            return `IN: ${group.itemNumber}, Quantity: ${group.quantity} from the shelf SN: ${group.shelf}`
        } else {
            return `IN: ${group.itemNumber}, Quantity: ${group.quantity}`
        }
    }
};

const CommitmentModal = ({groups, isVisible, shelf, onClose, onOk}) => {
    if (!isVisible) {
        return null;
    }
    return (
        <Modal
            visible={isVisible}
            onOk={onOk}
            onCancel={onClose}
        >
            Are you sure to relocate these items to the shelf {shelf} ?
        </Modal>
    );
};

const Batch = ({groupsInBatch, onRemove, onClear, setItem}) => {
    const [comment, setComment] = useState("");
    const [shelf, setShelf] = useState("");
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [createBatch, {loading}] = useMutation(CREATE_BATCH, {
        onError: (e) => {
            console.log(e);
            notification.error({message: `Shelf not found`});
        },
        onCompleted: () => {
            onClear();
            setComment("");
            setShelf("");
            setItem(null);
            notification.success({message: `Batch successfully created`});
        }
    });
    const submitBatchCreation = async () => {
        if (!groupsInBatch.length) {
            notification.error({message: "Batch is empty"});
            return null;
        }

        const inventoriesForMovement = groupsInBatch.reduce((array, g) => {
            if (g.type === INV_WITH_SERIAL) {
                return [...array, g];
            } else {
                return [...array, ...g.inventories.slice(0, g.quantity)];
            }
        }, []).map(inv => ({
            id: inv.id,
            itemId: inv.itemId,
            inventoryId: inv.inventoryId,
            itemNumber: inv.itemNumber,
            shelf: inv.shelf,
            description: inv.description,
            condition: inv.condition,
            mfgr: inv.mfgr,
            serialNumber: inv.serialNumber,
            status: inv.status,
            serviceOrder: inv.serviceOrder,
            quantity: inv.quantity,
            dateReceived: inv.dateReceived,
            dateBooked: inv.dateBooked,
            dateAudited: inv.dateAudited,
            warehouse: inv.warehouse,
        }));

        closeModal();

        return createBatch({
            variables: {
                comment,
                shelf,
                inventoriesForMovement,
            }
        });
    };

    const inventoriesCount = groupsInBatch
        .reduce((sum, group) => sum + (group.type === INV_WITH_SERIAL ? 1 : group.quantity), 0);

    const openModal = () => setIsModalVisible(true);

    const closeModal = () => setIsModalVisible(false);

    return (
        <Spin spinning={loading}>
            <CommitmentModal
                groups={groupsInBatch}
                onClose={closeModal}
                isVisible={isModalVisible}
                onOk={submitBatchCreation}
                shelf={shelf}
            />
            <Card
                title={
                    <Search
                        placeholder="Scan or type shelf number"
                        onSearch={openModal}
                        size="large"
                        addonBefore={`Move (${inventoriesCount}) Inventories to:`}
                        enterButton="Relocate"
                        disabled={!groupsInBatch.length || loading}
                        value={shelf}
                        onChange={(e) => setShelf(e.target.value)}
                    />
                }
            >
                {groupsInBatch.length ?
                    <Input.TextArea
                        style={{height: 105}}
                        className="mb-1"
                        placeholder="Rebin comment"
                        value={comment}
                        onChange={(e) => setComment(e.target.value)}
                    /> : null
                }
                <List
                    itemLayout="vertical"
                    size="large"
                    dataSource={groupsInBatch}
                    renderItem={group => (
                        <List.Item
                            extra={
                                <Button
                                    icon="minus"
                                    title="Remove"
                                    type="danger"
                                    onClick={onRemove(group.key)}
                                />
                            }>
                            <BatchGroup group={group}/>
                        </List.Item>
                    )}
                />
            </Card>
        </Spin>
    );
};

class BatchRebin extends React.Component {
    client;

    state = {
        groupsInBatch: [],
        item: null
    };

    setItem = (item) => this.setState({item});

    setGroupsInBatch = (groupsInBatch) =>
        this.setState({groupsInBatch});

    clearGroupsInBatch = () =>
        this.setGroupsInBatch([]);

    onAddGroupToBatch = (group) => {
        const {groupsInBatch} = this.state;
        this.setGroupsInBatch([
            ...groupsInBatch.filter(g => g.key !== group.key),
            group
        ]);
    };

    onRemoveGroupFromBatch = (key) => () => {
        const {groupsInBatch} = this.state;
        this.setGroupsInBatch(groupsInBatch.filter(group => key !== group.key));
    };

    constructor(props) {
        super(props);

        this.client = new ApolloClient({
            uri: REACT_APP_BATCH_REBIN_API,
            headers: {
                "Authorization": `Bearer ${props.token}`
            },
            defaultOptions: {
                watchQuery: {
                    fetchPolicy: 'no-cache',
                },
                query: {
                    fetchPolicy: 'no-cache',
                },
            },
            onError: ({graphQLErrors}) => {
                if (graphQLErrors && graphQLErrors
                    .find(error => error.extensions.code === 'UNAUTHENTICATED')) {
                    props.logout();
                }
            }
        });
    }

    render() {
        return (
            <ApolloProvider client={this.client}>
                <Scoped css={css}>
                    <div>
                        <PageHeader
                            title="Batch Rebin"
                            onBack={() => window.history.back()}
                        />
                        <Row className="batch" gutter={16}>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                                <ItemCard
                                    onAdd={this.onAddGroupToBatch}
                                    groupsInBatch={this.state.groupsInBatch}
                                    client={this.client}
                                    item={this.state.item}
                                    setItem={this.setItem}
                                />
                            </Col>
                            <Col xs={24} sm={24} md={24} lg={12} xl={12} xxl={8}>
                                <Batch
                                    groupsInBatch={this.state.groupsInBatch}
                                    onRemove={this.onRemoveGroupFromBatch}
                                    onClear={this.clearGroupsInBatch}
                                    setItem={this.setItem}
                                />
                            </Col>
                        </Row>
                    </div>
                </Scoped>
            </ApolloProvider>
        )
    }

}

const css = `
    & input+.ant-input-group-addon {
        padding: 0;
        border: 0;
    }
    & input+.ant-input-group-addon button {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        font-weight: bold;
    }
    & .ant-page-header {
        display: flex;
        align-items: center;
        background: inherit;
    }
    & .ant-page-header-heading-title {
        font-size: 20px;
        color: #f65729;
    }
    & .ant-card .ant-page-header-heading {
        display: flex;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    & .ant-card .ant-page-header-heading-extra {
        position: relative;
        top: auto;
        right: auto;
    }
    & .ant-card .ant-page-header-heading-title {
        font-size: 18px;
        color: rgba(0, 0, 0, 0.65);
    }
    & .ant-page-header, .batch {
        padding: 16px 0;
    }
    & .ant-list-empty-text {
        padding: 0;
    }
    & .ant-list-vertical .ant-list-item-extra {
        margin: auto 0 auto 10px;
    }
    & .ant-list-item-main {
        min-width: auto;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        display: flex;
        align-items: center;
        padding: 5px 11px;
    }
    & .ant-list-split .ant-list-item {
        border-bottom: none;
    }
    & .ant-list-lg .ant-list-item {
        padding-bottom: 8px;
    }
    & .batch {
        display: flex;
        align-items: stretch;
    }
    @media (max-width: 991px) {
        & .batch {
            flex-wrap: wrap;
        }
    }
    & .batch .ant-col {
        margin-bottom: 24px;
    }
    & .batch .ant-card, .ant-spin-nested-loading, .ant-spin-container {
        height: 100%;
    }
    & input[class*="ant-input"]:focus, textarea[class*="ant-input"]:focus {
        box-shadow: none !important;
    }
`;


export default BatchRebin;
