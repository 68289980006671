import React, {useEffect, useState} from "react";
import {
    Button,
    DatePicker,
    Input,
    PageHeader,
    Table,
    Radio,
    Icon,
    Modal,
    notification,
    Spin,
    Empty,
    Drawer,
    Col,
    Row,
    Card
} from "antd";
import moment from "moment";
import gql from 'graphql-tag';
import ApolloClient from "apollo-boost";
import {ApolloProvider, useLazyQuery} from "@apollo/react-hooks";
import {Scoped} from 'kremling';

const {RangePicker} = DatePicker;

const {REACT_APP_INVENTORY_SHELF_HISTORY_API} = process.env;

const FROM = 'FROM';
const TO = 'TO';

const FIND_BATCHES = gql`
    query($identity: String, $startDate: DateTime!, $endDate: DateTime!) {
        inventoryMovements(identity: $identity, startDate: $startDate, endDate: $endDate) {
            user {
                name
                email
            }
            fromShelf
            toShelf
            serialNumber
            comment
            eventDate
            description
            itemNumber
            itemId
            inventoryId
            mfgr
            key
            direction
        }
    }
`;

const INV_WITH_SERIAL = "INV_WITH_SERIAL";
const INV_WITHOUT_SERIAL = "INV_WITHOUT_SERIAL";

const isBlank = (str) => (!str || /^\s*$/.test(str));
const isNotBlank = (str) => !isBlank(str);

const groupInventories = (inventories) => {
    if (!inventories || !inventories.length) {
        return [];
    }

    const itemId = inventories[0].itemId;
    const itemNumber = inventories[0].itemNumber;
    const invWithoutSerialNumbers = inventories.filter(inv => isBlank(inv.serialNumber));

    const getShelf = (inv) => {
        if (inv.direction === TO) {
            if (!inv.fromShelf || isBlank(inv.fromShelf)) {
                return 'No prior registration';
            }
            return inv.fromShelf;
        }
        return inv.toShelf;
    };

    let invsWithoutSerialGroupByShelf = invWithoutSerialNumbers.reduce((map, invWSN) => {
        let shelf = getShelf(invWSN);
        if (!shelf) {
            shelf = 'No prior registration';
        }
        if (!map[shelf]) {
            map[shelf] = [invWSN];
        } else {
            map[shelf] = [...map[shelf], invWSN];
        }
        return map;
    }, {});

    return [
        ...inventories.filter(inv => isNotBlank(inv.serialNumber)).map(inv => ({
            ...inv,
            key: inv.serialNumber,
            type: INV_WITH_SERIAL,
            shelf: getShelf(inv),
        })),
        ...Object.keys(invsWithoutSerialGroupByShelf).map(shelf => ({
            key: shelf + itemNumber,
            shelf,
            itemId,
            itemNumber,
            inventories: invsWithoutSerialGroupByShelf[shelf],
            type: INV_WITHOUT_SERIAL,
        }))
    ];
};


const fromInventoryMovementsToBatchesGroupedByDate = (inventories) => {
    if (!inventories) {
        return null;
    }
    if (!inventories.length) {
        return {};
    }
    inventories = inventories.sort((a, b) => new Date(b.eventDate) - new Date(a.eventDate));
    const groupByNameAndDateTime = inventories.reduce((batches, inv) => {
        const name = inv.user ? inv.user.name : "";
        const eventDate = inv.eventDate;
        const key = inv.key;
        const direction = inv.direction;
        if (!batches[key]) {
            batches[key] = {
                name,
                eventDate,
                key,
                direction,
                shelf: direction === FROM ? inv.fromShelf : inv.toShelf,
                inventories: [inv],
            };
        } else {
            batches[key].inventories = [...batches[key].inventories, inv];
        }
        return batches;
    }, {});

    return Object.keys(groupByNameAndDateTime)
        .map(key => groupByNameAndDateTime[key])
        .map(batch => ({
            name: batch.name,
            eventDate: batch.eventDate,
            key: batch.key,
            shelf: batch.shelf,
            direction: batch.direction,
            groups: groupInventories(batch.inventories)
        })).reduce((map, batch) => {
            const date = moment(batch.eventDate).format("YYYY-MM-DD");
            if (!map[date]) {
                map[date] = [batch];
            } else {
                map[date] = [...map[date], batch];
            }
            return map;
        }, {});
};

const getInventoriesCount = (batch) => batch.groups
    .reduce(
        (sum, group) => sum + (group.type === INV_WITHOUT_SERIAL ? group.inventories.length : 1),
        0,
    );

const HistoryOptions = ({identity, onIdentityChanged, loading, period, dateRanges, setDateRanges, onDurationChanged, onSearch}) => (
    <div className="history-options">
        <Input
            className="shelf"
            size="large"
            placeholder="SN / IN / Shelf Number"
            value={identity}
            onKeyPress={event => event.key === 'Enter' ? onSearch() : null}
            onChange={onIdentityChanged}
            disabled={loading}
        />
        <div className="history-dates d-flex flex-wrap">
            <RangePicker
                size="large"
                style={{marginRight: '.5rem'}}
                separator="-"
                value={dateRanges}
                onChange={setDateRanges}
                allowClear={false}
                disabled={loading}
            />
            <Radio.Group
                className="history-date-options"
                value={period}
                size="large"
                buttonStyle="solid"
                disabled={loading}
                onChange={onDurationChanged}
            >
                <Radio.Button value="1M">1M</Radio.Button>
                <Radio.Button value="14D">2W</Radio.Button>
                <Radio.Button value="7D">1W</Radio.Button>
                <Radio.Button value="1D">1D</Radio.Button>
            </Radio.Group>
        </div>
        <Button
            className="history-btn"
            size="large"
            onClick={onSearch}
            disabled={loading}>
            Lookup
        </Button>
    </div>
);

const HistoryRaw = ({batch, onClick}) => {
    const className = batch.direction === FROM ? "direction-from" : "direction-to";
    const count = getInventoriesCount(batch);
    const eventDate = moment(batch.eventDate).format('DD-MM-YYYY HH:mm:ss');
    const iconType = batch.direction === FROM ? "double-right" : "double-left";
    return (
        <tr
            key={batch.key}
            className={className}
        >
            <td width={150}>
                <p className="bordered" onClick={onClick}>
                    {count} objects
                </p>
            </td>
            <td width={250}>{batch.direction} Shelf SN: <b>{batch.shelf}</b></td>
            <td width={120} style={{textAlign: 'center'}}>
                <Icon
                    className="movement-type-icon"
                    type={iconType}
                />
            </td>
            <td>
                {eventDate} by <b>{batch.name || null}</b>
            </td>
        </tr>
    );
};

const HistoryInventoriesTable = ({batch}) => batch ? (
    <div>
        <h3 className="mt-1 mb-2">
            In
            total {`${getInventoriesCount(batch)} items moved by ${batch.name}, ${batch.direction === FROM ? 'from' : 'to'} shelf SN: ${batch.shelf}, at ${moment(batch.eventDate).format('DD-MM-YYYY')} at ${moment(batch.eventDate).format('HH:mm:ss')}`}
        </h3>
        <div style={{maxHeight: 380, overflowX: "auto"}}>
            <Table
                bordered
                dataSource={batch ? batch.groups : []}
                rowKey={'key'}
                pagination={false}
                style={{height: "100%"}}
                columns={[
                    {
                        title: 'Item number',
                        dataIndex: 'itemNumber',
                        key: 'itemNumber',
                    },
                    {
                        title: 'Serial number',
                        render: ({serialNumber}) => serialNumber ? serialNumber : '-',
                        key: 'serialNumber',
                    },
                    {
                        title: 'Quantity',
                        render: ({inventories}) => inventories ? inventories.length : 1,
                        key: 'Quantity',
                    },
                    {
                        title: batch.direction === TO ? "From shelf" : "To shelf",
                        dataIndex: 'shelf',
                        key: 'shelf',
                    }
                ]}
            />
        </div>
    </div>
) : null;

const HistoryBatchTable = ({inventoryMovements, loading}) => {
    const batchesByDate = fromInventoryMovementsToBatchesGroupedByDate(inventoryMovements);
    let [selectedBatch, setSelectedBatch] = useState(null);

    const onSelectedBatchChanged = (selectedBatch) => () => setSelectedBatch(selectedBatch);

    const closeModal = () => setSelectedBatch(null);

    return (
        <Spin size={"large"} spinning={loading}>
            {inventoryMovements && inventoryMovements.length ?
                <>
                    <section style={{margin: '1rem 0'}}>
                        <table className="history-table">
                            {Object.keys(batchesByDate)
                                .map(date => (
                                    <div className="mb-2">
                                        <tr>
                                            <td width={150}/>
                                            <td width={250}/>
                                            <td width={120}>
                                                <p className="bordered">{date}</p>
                                            </td>
                                            <td/>
                                        </tr>
                                        {
                                            batchesByDate[date].map(batch => (
                                                <HistoryRaw
                                                    batch={batch}
                                                    onClick={onSelectedBatchChanged(batch)}
                                                />
                                            ))
                                        }
                                    </div>
                                ))
                            }
                        </table>
                    </section>
                    <Drawer
                        visible={selectedBatch}
                        onClose={closeModal}
                        placement={'bottom'}
                        height={500}
                    >
                        <HistoryInventoriesTable batch={selectedBatch}/>
                    </Drawer>
                </> :
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
            }
        </Spin>
    );
};

const Content = () => {
    const [findBatches, {loading, error, data: {inventoryMovements} = {}}] = useLazyQuery(
        FIND_BATCHES,
        {
            onError: () => {
                notification.error({message: "Something went wrong"});
            },
            fetchPolicy: 'no-cache',
        }
    );
    let [dateRanges, setDateRanges] = useState([moment().add(-1, "months"), moment()]);
    let [period, setPeriod] = useState("1M");
    let [identity, setIdentity] = useState("");

    const onIdentityChanged = (event) => setIdentity(event.target.value);

    const find = (identity, dateRanges) => {
        findBatches({
            variables: {
                identity: isBlank(identity) ? null : identity,
                startDate: dateRanges[0].startOf('day').utc().format("YYYY-MM-DD HH:mm:ss"),
                endDate: dateRanges[1].endOf('day').utc().format("YYYY-MM-DD HH:mm:ss")
            },
            fetchPolicy: 'no-cache'
        });
    };

    const onSearch = () => find(identity, dateRanges);

    const onDateRangesChanged = (e) => {
        setDateRanges(e);
        setPeriod("");
    };

    const onDurationChanged = (e) => {
        const value = e.target.value;
        const startDate = moment();
        const endDate = moment();
        if (value === "1M") {
            startDate.add(-1, "months");
        } else if (value === "14D") {
            startDate.add(-14, "days");
        } else if (value === "7D") {
            startDate.add(-7, "days");
        } else if (value === "1D") {
            startDate.add(-1, "days");
        }
        setDateRanges([startDate, endDate]);
        setPeriod(value);
        //find(shelf, [startDate, endDate]);
    };

    if (error) {
        return null;
    }

    return (
        <Scoped css={css}>
            <div>
                <PageHeader
                    title="Items Location History"
                    onBack={() => window.history.back()}
                />
                <Row className="batch" gutter={16}>
                    <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={20}>
                        <Card
                            title={
                                <HistoryOptions
                                    loading={loading}
                                    dateRanges={dateRanges}
                                    onDurationChanged={onDurationChanged}
                                    onSearch={onSearch}
                                    onIdentityChanged={onIdentityChanged}
                                    setDateRanges={onDateRangesChanged}
                                    identity={identity}
                                    period={period}
                                />
                            }
                        >
                            <HistoryBatchTable
                                inventoryMovements={inventoryMovements}
                                loading={loading}
                            />
                        </Card>
                    </Col>
                </Row>
            </div>
        </Scoped>
    )
};

class ItemsMovementHistory extends React.Component {
    client;

    constructor(props) {
        super(props);
        this.client = new ApolloClient({
            uri: REACT_APP_INVENTORY_SHELF_HISTORY_API,
            headers: {
                "Authorization": `Bearer ${props.token}`
            },
            onError: ({graphQLErrors, networkError, operation, forward}) => {
                if (graphQLErrors && graphQLErrors.find(error => error.extensions.code === 'UNAUTHENTICATED')) {
                    props.logout();
                }
            }
        });
    }

    render() {
        return (
            <ApolloProvider client={this.client}>
                <Content/>
            </ApolloProvider>
        )
    }

}

const css = `
    & .ant-page-header {
        padding: 16px 0;
    }
    & .ant-page-header {
        display: flex;
        align-items: center;
        background: inherit;
        padding: 16px 0;
    }
    & .ant-page-header-heading-title {
        font-size: 20px;
        color: #f65729;
    }
    .ant-radio-button-wrapper {
        font-weight: normal;
    }
    & input[class*="ant-input"]:focus, textarea[class*="ant-input"]:focus {
        box-shadow: none !important;
    }
    & .history-dates .ant-calendar-picker {
        width: 250px;
    }
    @media (max-width: 570px) {
        & .history-date-options {
            margin-top: .5rem;
        }
    }
    @media (max-width: 991px) {
        & .history-dates, & .shelf {
            margin-bottom: .5rem;
        }
    }
    @media (min-width: 992px) {
        & .history-options {
            display: flex;
            align-items: center;
        }
        & .shelf {
            max-width: 200px;
            margin-right: 1.5rem;
        }
        & .history-dates {
            margin-right: 1.5rem; 
        }
    }
    & .history-table {
        margin: 1rem 0;
    }
    & .history-table tr td:last-child {
        padding-left: 2rem;
    }
    & .history-table tr:not(:last-child) td {
        padding-bottom: 1rem;
    }
    & .history-range {
        display: flex; 
        flex-wrap: wrap;
    }
    & .bordered {
        width: 120px;
        display: inline-block;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        font-size: 16px;
        padding: 5px 10px;
        margin-bottom: 0;
        margin-right: .5rem;
        text-align: center;
    }
    & .direction-to p {
        border-color: #ff4d4f;
    }
    & .direction-to p:hover {
        background-color: #ff4d4f;
        color: #ffffff;
        cursor: pointer;
    }
    & .direction-from p {
        border-color: #1890ff;
    }
    & .direction-from p:hover {
        background-color: #1890ff;
        color: #ffffff;
        cursor: pointer;
    }
    & .movement-type-icon {
        font-size: 18px;
        margin: 0 2rem;
    }
    & .direction-to .movement-type-icon {
        color: #ff4d4f;
    }
    & .direction-from .movement-type-icon {
        color: #1890ff;
    }
`;

export default ItemsMovementHistory;
