import React from 'react';
import UmbrellaHeader from "./components/UmbrellaHeader";
import {Layout} from 'antd';
import {BrowserRouter, Switch, Route} from 'react-router-dom';
import Warehouse from "./components/pages/Warehouse";
import AppMart from "./components/pages/AppMart";
import BatchRebin from "./components/pages/BatchRebin";
import ItemsMovementHistory from "./components/pages/ItemsMovementHistory";
import SOCycleCount from "./components/pages/SOCycleCount";
import LocationCycleCount from "./components/pages/LocationCycleCount";
import NotFound from "./components/NotFound";
import './style/App.css';
import './style/helper.css';

const {Content} = Layout;

const App = ({tokenData, logout}) => (
    <BrowserRouter>
        <Layout style={{height: "100%"}}>
            <UmbrellaHeader logout={logout}/>
            <div style={{height: "100%", background: "#f0f2f5", overflow: "auto"}}>
            <Content>
                <Switch>
                    <Route exact path="/" render={(props) => <AppMart
                        {...props}
                        logout={logout}
                        token={tokenData.accessToken}
                    />}/>
                    <Route exact path="/warehouse_dashboard" component={Warehouse}/>
                    <Route path="/move_items_in_batch" render={(props) => <BatchRebin
                        {...props}
                        logout={logout}
                        token={tokenData.accessToken}
                    />
                    }/>
                    <Route path="/items_movement_history" render={(props) => <ItemsMovementHistory
                        {...props}
                        logout={logout}
                        token={tokenData.accessToken}
                    />}/>
                    <Route path="/so_cycle_count" render={(props) => <SOCycleCount
                        {...props}
                        logout={logout}
                        token={tokenData.accessToken}
                    />}/>
                    <Route path="/location_cycle_count" render={(props) => <LocationCycleCount
                        {...props}
                        logout={logout}
                        token={tokenData.accessToken}
                    />}/>
                    <Route component={NotFound}/>
                </Switch>
            </Content>
            </div>
        </Layout>
    </BrowserRouter>
);

export default App;
