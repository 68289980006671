import React from "react";
import {Col, Icon, notification, Rate, Row, Spin} from "antd";
import {NavLink} from "react-router-dom";
import gql from "graphql-tag";
import {ApolloProvider, useMutation, useQuery} from '@apollo/react-hooks';
import ApolloClient from 'apollo-boost';
import icon from '../../images/icon.svg';

const {REACT_APP_DASHBOARD_URL} = process.env;

const FIND_APPS = gql`
    query { 
        apps {
            id
            name
            description
            path
            isFavorite
        }
    }
`;

const UPDATE_APP_FAVORITE_STATE = gql`
    mutation updateAppFavoriteState($id: ID!, $isFavorite: Boolean!) {
        updateAppFavoriteState(id: $id, isFavorite: $isFavorite) {
            id
            name
            description
            path
            isFavorite        
        }
    }
`;

const Favorite = ({id, isFavorite}) => {
    const [updateAppFavoriteState, {loading}] = useMutation(UPDATE_APP_FAVORITE_STATE, {
        onCompleted: ({updateAppFavoriteState}) => {
            if (updateAppFavoriteState.isFavorite) {
                notification.success({
                    message: `App "${updateAppFavoriteState.name}" successfully added to favorites`
                });
            } else {
                notification.success({
                    message: `App "${updateAppFavoriteState.name}" successfully removed from favorites`
                });
            }
        },
        onError: () => {
            notification.error({
                message: "System error"
            });
        },
    });
    return (
        <div className="favorite-app">
            <Spin
                spinning={loading}
                size="small"
            >
                <Rate
                    character={<Icon type="heart"/>}
                    count={1}
                    value={isFavorite ? 1 : 0}
                    onChange={value => {
                        if (value) {
                            updateAppFavoriteState({
                                variables: {
                                    id,
                                    isFavorite: value === 1
                                }
                            });
                        } else {
                            updateAppFavoriteState({
                                variables: {
                                    id,
                                    isFavorite: value === 1
                                }
                            });
                        }
                    }}

                />
            </Spin>
        </div>
    );
};

const App = ({app}) => app ? (
    <Col xs={24} sm={12} md={12} lg={6} xl={6} xxl={4}>
        <NavLink to={app.path}>
            <div className="app">
                {app.name}
                <Favorite
                    isFavorite={app.isFavorite}
                    id={app.id}
                />
            </div>
        </NavLink>
    </Col>
) : null;

const AppList = () => {
    const {loading, error, data} = useQuery(FIND_APPS);
    if (error) {
        return null;
    }
    const apps = data ? data.apps : [];
    return (
        <Spin spinning={loading} size={"large"}>
            <div className="dashboard-apps">
                <Row type="flex" gutter={16}>
                    {apps.map(app => (
                        <App app={app}/>))}
                </Row>
            </div>
        </Spin>
    );
};

export default class AppMart extends React.Component {

    client;

    constructor(props) {
        super(props);
        this.client = new ApolloClient({
            uri: REACT_APP_DASHBOARD_URL,
            headers: {
                "Authorization": `Bearer ${props.token}`
            },
            onError: ({graphQLErrors, networkError, operation, forward}) => {
                if (graphQLErrors && graphQLErrors.find(error => error.extensions.code === 'UNAUTHENTICATED')) {
                    props.logout();
                }
            }
        });
    }

    render() {
        return (
            <ApolloProvider client={this.client}>
                <section className="dashboard-header d-flex" style={{marginBottom: 40}}>
                    <img src={icon} height={31}/>
                    <div className="dashboard-title">
                        <h1>Apps</h1>
                    </div>
                </section>
                <AppList/>
            </ApolloProvider>
        )
    }

}



