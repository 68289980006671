import React from "react";
import { withRouter } from "react-router-dom";
import { Result, Button} from "antd";

export default withRouter(({ history }) => (
    <Result
        status="404"
        title="404"
        subTitle="Sorry, the page you visited does not exist."
        extra={<Button onClick={() => history.push("/")}>Back Home</Button>}
    />
));