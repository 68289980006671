import React, {useState} from "react";
import {Layout, Dropdown, Icon, AutoComplete, Menu, Avatar} from "antd";
import {withRouter} from "react-router-dom";
import logo from '../images/pivit-white.svg';

const {Header} = Layout;

const menu = (history) => (
    <Menu onClick={({key}) => history.push(key)}>
        <Menu.Item style={{margin: 20}} key="/">
            App Mart
        </Menu.Item>
        <Menu.Item style={{margin: 20}} key="/warehouse_dashboard">
            Warehouse Dashboard
        </Menu.Item>    
    </Menu>
);

const APPS = [{
    key: 1,
    value: "/move_items_in_batch",
    text: "Moves Items In Batch"
}, {
    key: 2,
    value: "/items_movement_history",
    text: "Items movement history"
}];

export default withRouter(({history, logout}) => {
    let [findedApps, setFindedApps] = useState([]);
    let [searchText, setSearchText] = useState("");

    const onSearchApp = (input) => {
        if (input) {
            setFindedApps(
                APPS.filter(
                    app => app.text.toLowerCase().includes(input.toLowerCase())
                )
            )
        }
    };

    const onSelectApp = (value) => {
        history.push(value);
        setSearchText("");
        setFindedApps([]);
    };

    const userOptions = (
        <Menu style={{ width: 120, top: 10 }}>
            <Menu.Item>
                <a onClick={(e) => {
                    e.preventDefault();
                    logout();
                }}>Logout</a>
            </Menu.Item>
        </Menu>
    );

    return (
        <Header className="umbrella-header d-flex justify-space-between">
            <div>
                <Dropdown overlay={menu(history)} trigger={['click']}>
                    <Icon
                        className="trigger"
                        type="menu"
                    />
                </Dropdown>
                <img src={logo} height='20' className='ml-1'/>
            </div>
            <div>
                <AutoComplete
                    value={searchText}
                    onChange={setSearchText}
                    placeholder="Search Apps and Widgets"
                    dataSource={findedApps}
                    onSearch={onSearchApp}
                    onSelect={onSelectApp}
                    style={{width: 250, marginRight: '1rem'}}
                />
                <Dropdown overlay={userOptions} trigger={['click']}>
                    <a className="ant-dropdown-link" href="#">
                        <Avatar icon="user" className="user-icon" />
                    </a>
                </Dropdown>
            </div>
        </Header>
    );
});
