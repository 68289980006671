import React, {useEffect, useRef, useState} from "react";
import {Scoped} from 'kremling';
import {
    Button,
    Card,
    Col,
    Empty,
    Input,
    PageHeader,
    Row,
    Spin,
    Table,
    notification,
    List,
    Drawer
} from "antd";
import gql from "graphql-tag";
import ApolloClient from "apollo-boost";
import {ApolloProvider, useApolloClient} from "@apollo/react-hooks";

const {REACT_APP_LOCATION_CYCLE_COUNT_API} = process.env;

const GET_BY_SERIAL_OR_ITEM_NUMBER = gql`
    query ($identity: String!) {
        getInventoriesBySerialOrItemNumber(identity: $identity) {
            id
            itemId
            inventoryId
            itemNumber
            shelf
            mfgr
            serialNumber
            quantity
            warehouse
            so
        }
  }
`;

const GET_INVENTORIES_BY_SHELF = gql`
    query($shelf: String!) {
        getInventoriesByShelf(shelf: $shelf) {
            id
            itemId
            inventoryId
            itemNumber
            shelf
            description
            mfgr
            serialNumber
            warehouse
            so
            quantity
        }
    }
`;

const CHANGE_LOCATION = gql`
    mutation($shelf: String!, $inventories: [InventoryForMovement!]!) {
        createBatch(shelf: $shelf, inventories: $inventories) {
            id
            itemId
            inventoryId
            itemNumber
            shelf
            description
            mfgr
            serialNumber
            warehouse
            so
            quantity
        }
    }
`;

const SEND_EMAIL = gql`
    mutation($email: String!, $text: String!) {
        sendToEmail(email: $email, text: $text)
    }
`;

const FINE_ITEM = 'fine_item';
const PART_OF_SO = 'part_of_so';
const UNKNOWN_ITEM = 'unknown_item';
const INCORRECT_LOCATION = 'incorrect_location';
const UNSCANNED = 'unscanned';

const INV_WITH_SERIAL = "INV_WITH_SERIAL";
const INV_WITHOUT_SERIAL = "INV_WITHOUT_SERIAL";

const IS_NOT_BLANK = (str) => str && str.trim().length;
const IS_BLANK = (str) => !IS_NOT_BLANK(str);

const GENERATE_KEY = (inv) => {
    if (inv.serialNumber) {
        return inv.serialNumber;
    }
    const shelf = inv.shelf ? inv.shelf : "";
    const so = inv.so ? inv.so : "";
    return `${shelf}|${so}`;
};

const GROUP_INVENTORIES = (inventories, shelf) => {
    if (!inventories || !inventories.length) {
        return [];
    }

    const invWithoutSerialNumbers = inventories.filter(inv => IS_BLANK(inv.serialNumber) && IS_NOT_BLANK(inv.inventoryId));
    let invsWithoutSerialGroupByKey = {};

    invWithoutSerialNumbers.forEach(invWSN => {
        const key = GENERATE_KEY(invWSN);
        if (!invsWithoutSerialGroupByKey[key]) {
            invsWithoutSerialGroupByKey[key] = [invWSN];
        } else {
            invsWithoutSerialGroupByKey[key] = [...invsWithoutSerialGroupByKey[key], invWSN];
        }
    });

    const groups = [
        ...inventories.filter(inv => IS_BLANK(inv.inventoryId)).map(inv => ({
            key: inv.id,
            status: UNKNOWN_ITEM
        })),
        ...inventories.filter(inv => IS_NOT_BLANK(inv.serialNumber)).map(inv => ({
            ...inv,
            key: inv.serialNumber,
            type: INV_WITH_SERIAL,
        })),
        ...Object.keys(invsWithoutSerialGroupByKey).map(key => {
            const inventory = invsWithoutSerialGroupByKey[key][0];
            return ({
                key,
                shelf: inventory.shelf,
                so: inventory.so,
                reconciled: inventory.reconciled,
                itemId: inventory.itemId,
                itemNumber: inventory.itemNumber,
                mfgr: inventory.mfgr,
                quantity: 1,
                inventories: invsWithoutSerialGroupByKey[key],
                type: INV_WITHOUT_SERIAL,
            })
        })
    ];

    if (shelf) {
        console.log(groups);
        return groups.map(group => {
            if (group.so && group.so !== "0") {
                return {...group, status: PART_OF_SO}
            }
            if (!group.itemId) {
                return {...group, status: UNKNOWN_ITEM}
            }
            if (group.reconciled) {
                return {...group, status: UNSCANNED};
            }
            if (group.shelf === shelf) {
                return {...group, status: FINE_ITEM};
            }
            return {...group, status: INCORRECT_LOCATION};
        });
    }

    return groups;
};


const GET_INVENTORY_STATUS = ({status, so}) => {
    switch (status) {
        case UNKNOWN_ITEM:
            return "NOT IDENTIFIED INVENTORY";
        case INCORRECT_LOCATION:
            return "WRONG SHELF";
        case UNSCANNED:
            return "UNSCANNED";
        case FINE_ITEM:
            return "FINE ITEM";
        case PART_OF_SO:
            return "PART OF SO #" + so;
    }
    return "";
};

const COLUMNS = [
    {
        title: 'Item Number',
        dataIndex: 'itemNumber',
        key: 'itemNumber',
    },
    {
        title: 'Quantity',
        key: 'quantity',
        render: ({inventories = []}) => inventories.length || 1,
    },
    {
        title: 'Serial Number',
        dataIndex: 'serialNumber',
        key: 'serialNumber',
    },
    {
        title: 'Mfgr',
        dataIndex: 'mfgr',
        key: 'mfgr',
    },
    {
        title: 'Shelf #',
        dataIndex: 'shelf',
        key: 'shelf',
    }
];

const DrawerContent = ({modalType, inventories, relocate, sendEmail, onClose, shelf}) => {
    let [selected, setSelected] = useState([]);
    let [location, setLocation] = useState("");

    const rowSelection = {
        onChange: (selectedRowKeys, selectedRows) => {
            setSelected(selectedRows);
        }
    };
    const changeLocations = async () => {
        onClose();
        try {
            await relocate(selected, location);
        } catch (e) {
            notification.error({message: "System Error"})
        }
    };

    const onLocationChange = (event) => setLocation(event.target.value);

    let [email, setEmail] = useState("");

    const onEmailChange = (event) => setEmail(event.target.value);

    const onSendEmail = async () => {
        const mail = email;
        let text = "<h3>Unknown inventories<h3><br/><br/><ul>";
        text += inventories.reduce((str, inv) => str + `<li>${inv.key}</li>`, "");
        text += "</ul>";
        onClose();
        try {
            await sendEmail(mail, text);
            notification.success({message: `Unknown inventories successfully send to ${mail}`});
        } catch (e) {
            notification.error({message: `Something went wrong while sending to email ${mail}`});
        } finally {
        }
    };

    let additionalContent = null;

    let columns = [];

    if (modalType === UNKNOWN_ITEM) {
        columns = [{
            title: 'SN/IQ label',
            dataIndex: 'key',
            key: 'key',
        }];
    } else if (modalType === PART_OF_SO) {
        columns = [...COLUMNS, {
            title: 'SO',
            dataIndex: 'so',
            key: 'so',
        }];
    } else {
        columns = COLUMNS;
    }

    switch (modalType) {
        case "unknown_item":
            additionalContent = (
                <div className="d-flex">
                    <Input
                        placeholder="Email"
                        className="mr-1"
                        value={email}
                        onChange={onEmailChange}
                    />
                    <Button
                        disabled={!inventories.length || email === ""}
                        onClick={onSendEmail}
                    >Send</Button>
                </div>
            );
            break;
        case "incorrect_location":
            additionalContent = (
                <div className="d-flex">
                    <Input
                        placeholder="Shelf #"
                        className="mr-1"
                        value={location}
                        onChange={onLocationChange}
                    />
                    <Button
                        onClick={changeLocations}
                        disabled={!selected.length || location === ""}>
                        Relocate
                    </Button>
                </div>
            );
            break;
    }

    return (
        <div className="mb-2" style={{maxHeight: 400, overflowX: "auto"}}>
            <Table
                bordered
                className="mb-1"
                style={{height: "100%"}}
                columns={columns}
                dataSource={inventories}
                pagination={false}
                rowKey={"key"}
                rowSelection={modalType === 'incorrect_location' ? rowSelection : ''}
            />
            {additionalContent}
        </div>
    )
};

const ResultTitle = ({inventories, relocate, sendEmail, shelf}) => {
    let [drawerTitle, setDrawerTitle] = useState(null);
    let [chosenInventories, setChosenInventories] = useState(null);
    let [modalType, setModalType] = useState(null);

    const fineItems = inventories.filter(inv => inv.status === FINE_ITEM);
    const partOfSo = inventories.filter(inv => inv.so && inv.so !== "0");
    const unknowItems = inventories.filter(inv => inv.status === UNKNOWN_ITEM);
    const incorrectLocation = inventories.filter(inv => inv.status === INCORRECT_LOCATION);
    const unscanned = inventories.filter(inv => inv.status === UNSCANNED);

    const closeDrawer = () => {
        setDrawerTitle(null);
        setChosenInventories(null);
        setModalType(null);
    };

    const openDrawer = (modalType) => () => {
        let inventories = [];
        switch (modalType) {
            case PART_OF_SO:
                setDrawerTitle(`Items that are Part of some SO (${partOfSo.length} items)`);
                inventories = partOfSo;
                break;
            case UNKNOWN_ITEM:
                setDrawerTitle(`List of Not Identified Items (${unknowItems.length} items)`);
                inventories = unknowItems;
                break;
            case INCORRECT_LOCATION:
                setDrawerTitle(`List of Items with Incorrect Location (${incorrectLocation.length} items)`);
                inventories = incorrectLocation;
                break;
            case UNSCANNED:
                setDrawerTitle(`Unscanned Items (${unscanned.length} items)`);
                inventories = unscanned;
                break;
            case FINE_ITEM:
                setDrawerTitle(`Items with Correct Location (${fineItems.length} items)`);
                inventories = fineItems;
                break;
        }
        if (inventories.length) {
            setChosenInventories(inventories);
            setModalType(modalType);
        }
    };


    return (
        <>
            <div className="location-cycle-result" style={{marginBottom: 4}}>
                Based on the scanned items:
                <p
                    className="fine_item cursor-pointer"
                    onClick={openDrawer(FINE_ITEM)}>
                    {fineItems.length}
                </p> items are in the correct location,
                <p className="incorrect_location cursor-pointer"
                   onClick={openDrawer(INCORRECT_LOCATION)}>
                    {incorrectLocation.length}
                </p> are in the wrong location,
                <p
                    className="unknown_item cursor-pointer"
                    onClick={openDrawer(UNKNOWN_ITEM)}>{unknowItems.length}
                </p> cannot be found,
            </div>
            <div className="location-cycle-result">
                <p
                    className="part_of_so cursor-pointer"
                    onClick={openDrawer(PART_OF_SO)}
                    style={{marginLeft: 0}}
                >
                    {partOfSo.length}
                </p> are reserved for a SO, and
                <p
                    className="unscanned cursor-pointer"
                    onClick={openDrawer(UNSCANNED)}
                >
                    {unscanned.length}
                </p> have not been scanned.
                <Drawer
                    title={drawerTitle}
                    visible={modalType}
                    onClose={closeDrawer}
                    placement="bottom"
                    height={500}
                >
                    <DrawerContent
                        modalType={modalType}
                        inventories={chosenInventories}
                        relocate={relocate}
                        sendEmail={sendEmail}
                        onClose={closeDrawer}
                        shelf={shelf}
                    />
                </Drawer>
            </div>
        </>
    );
};

const Inventory = ({inventory}) => {
    if (inventory.serialNumber) {
        return (
            <div className="inventory-title">
                SN: {inventory.serialNumber}
            </div>
        );
    }
    if (inventory.itemNumber) {
        return (
            <div className="inventory-title">
                IQ: {inventory.itemNumber}
                {inventory.mfgr ? `, MFGR: ${inventory.mfgr}` : ""}
                {inventory.inventories ? ", QTY: " + inventory.inventories.length : null}
            </div>
        )
    }
    return (
        <div className="inventory-title">
            SN/IQ label: {inventory.key}
        </div>
    )
};

const InventoryList = ({inventories, removeInventory}) => inventories && inventories.length > 0 ?
    (
        <List
            className="inventories"
            itemLayout="vertical"
            size="large"
            grid={{
                gutter: 40,
                xs: 1,
                sm: 1,
                md: 1,
                lg: 2,
                xl: 2,
                xxl: 2,
            }}
            dataSource={inventories}
            renderItem={inventory => (
                <List.Item
                    style={{display: 'flex'}}
                    className={inventory.status}
                    extra={
                        <Button
                            icon="minus"
                            title="Remove"
                            type="danger"
                            className={inventory.status === 'missing' ? 'disabled' : ''}
                            onClick={removeInventory(inventory.key)}
                            disabled={inventory.status === 'missing'}
                        />
                    }>
                    <Inventory inventory={inventory}/>
                    <span className="inventory-status">
                        {GET_INVENTORY_STATUS(inventory)}
                    </span>
                </List.Item>
            )}
        >
        </List>
    ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
    )
;

const Content = () => {
        const [inventories, setInventories] = useState([]),
            [identity, setIdentity] = useState(''),
            [loading, setLoading] = useState(false),
            [shelf, setShelf] = useState(""),
            [reconcileShelf, setReconcileShelf] = useState(null);

        const client = useApolloClient();

        const onShelfChanged = (event) => setShelf(event.target.value);

        const identityRef = useRef(null);

        useEffect(() => {
            identityRef.current.focus()
        }, []);

        const clearGroups = (e) => {
            e.preventDefault();
            setInventories([]);
            setShelf("");
            setReconcileShelf(null);
        };

        const addInventories = (invs) => {
            const inventoryIds = inventories.map(it => it.id);
            setInventories([
                ...inventories.map(inv => {
                    const inventory = invs.find(inv2 => inv2.id === inv.id);
                    return inventory ? inventory : inv;
                }),
                ...invs.filter(inv => !inventoryIds.includes(inv.id))
            ]);
        };

        const findInventories = async (identity) => {
            setLoading(true);
            try {
                const {data: {getInventoriesBySerialOrItemNumber: invs}} = await client.query({
                    query: GET_BY_SERIAL_OR_ITEM_NUMBER,
                    variables: {identity},
                    fetchPolicy: 'no-cache'
                });
                if (invs && invs.length) {
                    if (invs.find(it => it.serialNumber && it.itemNumber === identity)) {
                        notification.error({
                            message: "It is not allowed to use Item Number, if Serial number for this item exists."
                        });
                    } else {
                        addInventories(invs);
                    }
                } else {
                    addInventories([{id: identity}]);
                }
            } catch (error) {
                console.log(error);
                notification.error({message: "Inventories Not Found"})
            } finally {
                setLoading(false);
            }

            setIdentity('');
            if (identityRef.current) {
                identityRef.current.focus();
            }
        };

        const sendEmail = async (email, text) => {
            setLoading(true);
            try {
                await client.mutate({
                    mutation: SEND_EMAIL,
                    variables: {
                        email,
                        text
                    }
                });
            } catch (e) {
                console.log(e);
            } finally {
                setLoading(false);
            }
        };

        const relocate = async (groups, newShelf) => {
            setLoading(true);
            const _newShelf = String(newShelf);
            try {
                const {data: {createBatch: invs}} = await client.mutate({
                    mutation: CHANGE_LOCATION,
                    variables: {
                        inventories: groups.reduce((array, g) => {
                            if (g.type === INV_WITH_SERIAL) {
                                return [...array, g];
                            } else {
                                return [...array, ...g.inventories];
                            }
                        }, []).map(inv => ({
                            id: inv.id,
                            itemId: inv.itemId,
                            inventoryId: inv.inventoryId,
                            itemNumber: inv.itemNumber,
                            shelf: inv.shelf,
                            description: inv.description,
                            mfgr: inv.mfgr,
                            serialNumber: inv.serialNumber,
                            warehouse: inv.warehouse,
                            so: inv.so,
                            quantity: inv.quantity,
                        })),
                        shelf: _newShelf,
                    },
                    fetchPolicy: 'no-cache'
                });
                addInventories(invs);
            } catch (e) {
                console.log(e);
                notification.error({message: "System Error"});
            } finally {
                setLoading(false);
            }
        };

        const onReconcile = async () => {
            setLoading(true);
            const _inventories = [...inventories];
            try {
                setInventories(_inventories.map(inv => ({...inv, reconciled: false})));
                const _shelf = String(shelf);
                const {data: {getInventoriesByShelf: invs}} = await client.query({
                    query: GET_INVENTORIES_BY_SHELF,
                    variables: {shelf: _shelf},
                    fetchPolicy: 'no-cache'
                });
                setReconcileShelf(_shelf);
                if (invs && invs.length) {
                    const ids = _inventories.map(it => it.id);
                    addInventories(invs.map(it => {
                        if (ids.includes(it.id)) {
                            return it;
                        }
                        return ({...it, reconciled: true});
                    }));
                } else {
                    notification.error({message: `Shelf #${_shelf} is either empty or does not exist or expired`});
                }
            } catch (e) {
                console.log(e);
                notification.error({message: "System error"});
            } finally {
                setLoading(false);
            }
        };

        const onRemoveGroup = (key) => () => {
            setInventories(inventories.filter(it => !(it.serialNumber === key || it.id === key || GENERATE_KEY(it) === key)));
        };

        const onPasteIdentity = async (e) => {
            e.preventDefault();
            const identity = e.clipboardData.getData('Text');
            if (IS_NOT_BLANK(identity)) {
                setIdentity(identity);
                await findInventories(identity);
            }
        };

        const onIdentityChanged = (e) => setIdentity(e.target.value);

        const groups = GROUP_INVENTORIES(inventories, reconcileShelf);

        return (
            <Scoped css={css}>
                <div>
                    <PageHeader
                        title="Location Cycle Count"
                        onBack={() => window.history.back()}
                    />
                    <Row className="location-cycle-form" gutter={16}>
                        <Col xs={24} sm={24} md={8} lg={6} xl={4} xxl={4}>
                            <Input
                                ref={identityRef}
                                placeholder="SN/IQ label"
                                size="large"
                                onPaste={onPasteIdentity}
                                value={identity}
                                onChange={onIdentityChanged}
                                onKeyPress={event => event.key === 'Enter' ? findInventories(identity) : null}
                                disabled={loading}
                                addonAfter={<Button
                                    type="button"
                                    className="ant-btn ant-input-search-button ant-btn-primary ant-btn-lg"
                                    disabled={loading || IS_BLANK(identity)}
                                    onClick={() => findInventories(identity)}
                                >+</Button>}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={6} xl={4} xxl={4}>
                            <Input
                                placeholder="Shelf number"
                                size="large"
                                disabled={loading}
                                value={shelf}
                                onChange={onShelfChanged}
                            />
                        </Col>
                        <Col xs={24} sm={24} md={8} lg={6} xl={4} xxl={4}>
                            <Button
                                type="default"
                                size="large"
                                block
                                className="reconcile"
                                htmlType="submit"
                                onClick={onReconcile}
                                disabled={IS_BLANK(shelf) || !groups.length}
                            >Reconcile</Button>
                        </Col>
                    </Row>
                    <ResultTitle
                        inventories={groups}
                        relocate={relocate}
                        sendEmail={sendEmail}
                        shelf={shelf}
                    />
                    <Row>
                        <Col xs={24} sm={24} md={24} lg={24} xl={22} xxl={16}>
                            <Spin spinning={loading} size={"large"}>
                                <Card
                                    title="Inventories"
                                    style={{maxWidth: 1025}}
                                    extra={
                                        <a href="#" onClick={clearGroups}>
                                            Clear
                                        </a>
                                    }>
                                    <InventoryList
                                        inventories={groups}
                                        removeInventory={onRemoveGroup}
                                    />
                                </Card>
                            </Spin>
                        </Col>
                    </Row>
                </div>
            </Scoped>
        )
    }
;

const LocationCycleCount = ({token, logout}) => {
    const client = new ApolloClient({
        uri: REACT_APP_LOCATION_CYCLE_COUNT_API,
        headers: {
            "Authorization": `Bearer ${token}`
        },
        onError: ({graphQLErrors}) => {
            if (graphQLErrors && graphQLErrors.find(error => error.extensions.code === 'UNAUTHENTICATED')) {
                logout();
            }
        },
    });
    return (
        <ApolloProvider client={client}>
            <Content client={client}/>
        </ApolloProvider>
    );
};

const css = `
    & .ant-page-header {
        display: flex;
        align-items: center;
        background: inherit;
    }
    & .ant-page-header-heading-title {
        font-size: 20px;
        color: #f65729;
    }
    & .ant-page-header, .location-cycle-form {
        padding: 16px 0;
    }
    & input[class*="ant-input"]:focus {
        box-shadow: none !important;
    }
    & .location-cycle-form div:not(:last-child) {
        margin-bottom: 8px;
    }
    & .location-cycle-form input+.ant-input-group-addon {
        padding: 0;
        border: 0;
    }
    & .location-cycle-form input+.ant-input-group-addon button {
        width: 100%;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        font-weight: bold;
    }
    & .location-cycle-result {
        font-size: 16px;
        line-height: 2.2;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 24px;
    }
    & .location-cycle-result p {
        line-height: 1.5;
        margin: 0 5px;
        border-radius: 4px;
        font-weight: bold;
        text-decoration: underline;
    }
    & .location-cycle-result p {
        padding: 2px 10px;
    }
    & .location-cycle-result p:hover {
        text-decoration: none;
        color: #ffffff;
    }
    & p.part_of_so, .part_of_so .ant-list-item-main {
        background: #ff4d4f;
        border-color: #ff4d4f !important;
        color: #ffffff;
    }
    & p.part_of_so:hover {
        background: #ff7875;
        border-color: #ff7875 !important;
    }
    & p.unknown_item, .unknown_item .ant-list-item-main {
        border: 1px dashed #1890ff !important;
    }
    & p.unknown_item:hover {
        background: #1890ff;
    }
    & p.incorrect_location, .incorrect_location .ant-list-item-main {
        border: 1px solid #ff4d4f !important;
    }
    & p.incorrect_location:hover {
        background: #ff4d4f;
    }
    & p.unscanned, .unscanned .ant-list-item-main {
        border: 1px solid #d9d9d9 !important;
    }
    & p.unscanned:hover {
        background: #d9d9d9;
    }
    & p.fine_item, .fine_item .ant-list-item-main {
        border: 1px solid #1890ff !important;
    }
    & p.fine_item:hover {
        background: #1890ff;
    }
    & .ant-card-head-title {
        color: rgba(0, 0, 0, 0.65);
    }
    & .ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
        font-weight: bold;
    }
    @media (max-width: 767px) {
        & .reconcile {
            margin-top: 16px;
            margin-bottom: 8px;
        }
    }
    & .ant-list-vertical .ant-list-item-extra {
        margin: auto 0 auto 10px;
    }
    & .ant-list-item-main {
        min-width: auto;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 11px;
    }
    @media (min-width: 1200px) {
        & .ant-list-item {
            max-width: 500px;
        }
    }
    & .ant-btn.disabled {
        visibility: hidden;
    }
    & .inventory-title, .inventory-status {
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: 21px;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    & .inventory-status {
        font-size: 10px;
        max-height: 15px;
        color: #d9d9d9;
        margin-left: 10px;
    }
`;

export default LocationCycleCount;
