import {WebAuth} from "auth0-js";

const {REACT_APP_AUTH0_CLIENT_ID, REACT_APP_AUTH0_DOMAIN, REACT_APP_AUTH0_REDIRECT_URI} = process.env;

const auth0Config = {
    domain: REACT_APP_AUTH0_DOMAIN,
    clientID: REACT_APP_AUTH0_CLIENT_ID,
    responseType: 'token',
    scope: 'openid email profile',
    audience: 'umbrella-api-gw',
    redirectUri: REACT_APP_AUTH0_REDIRECT_URI
};


export default class Auth {

    auth0;

    constructor(onComplete) {
        this.auth0 = new WebAuth(auth0Config);
        const tokenData = this.getTokenData();
        console.log('tokenData', tokenData);
        if (tokenData == null) {
            console.log("hash", document.location.hash);
            if (/access_token|error/.test(document.location.hash)) {
                this.auth0.parseHash((err, authResult) => {
                    console.log('authResult', authResult);
                    if (authResult && authResult.accessToken) {
                        this.saveTokenData(authResult);
                        window.location = "";
                    } else if (err) {
                        console.log('error while auth', err);
                        this.authorize();
                    }
                });
            } else {
                this.authorize();
            }
        } else {
            onComplete(tokenData, this.logout);
        }
    }

    logout = () => {
        console.log('logout');
        localStorage.clear();
        this.auth0.logout({
            clientID: auth0Config.clientID,
            returnTo: auth0Config.redirectUri
        });
    };

    authorize = () => {
        console.log('authorize');
        localStorage.clear();
        this.auth0.authorize();
    };

    saveTokenData = (token) => localStorage.setItem("tokenData", JSON.stringify(token));

    getTokenData = () => {
        const tokenData = localStorage.getItem("tokenData");
        return tokenData && JSON.parse(tokenData);
    }

}
