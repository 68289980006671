import React from "react";
import {Col, Row} from "antd";
import {NavLink} from "react-router-dom";

export default () => (
    <div>
        <section className="dashboard-header d-flex">
            <h1 style={{marginBottom: '40px'}}>Warehouse dashboard</h1>
        </section>
        <div className="dashboard-apps" gutter={16}>
            <Row type="flex" gutter={16}>
                <Col
                    xs={24} sm={12} md={12} lg={6} xl={6} xxl={4}
                    className='p-relative'>
                  <NavLink to="/move_items_in_batch">
                      <div className="app">Moves Items In Batch</div>
                  </NavLink>
                </Col>
                <Col
                    xs={24} sm={12} md={12} lg={6} xl={6} xxl={4}
                    className='p-relative'>
                    <NavLink to="/items_movement_history">
                        <div className="app">Items movement history</div>
                    </NavLink>
                </Col>
            </Row>
        </div>
    </div>
);
