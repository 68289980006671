import React, {useEffect, useRef, useState} from "react";
import {Scoped} from 'kremling';
import {
    Button,
    Card,
    Col,
    Drawer,
    Empty,
    Input,
    List,
    notification,
    PageHeader,
    Row,
    Spin,
    Table
} from "antd";
import gql from "graphql-tag";
import ApolloClient from "apollo-boost";

const {REACT_APP_SO_CYCLE_COUNT_API} = process.env;

const SO_PART = 'so-part';
const MISSING = 'missing';
const REDUNDANT = 'redundant';
const NOT_FOUND = 'not_found';
const SCANNED = 'scanned';

const GET_BY_SERIAL_OR_ITEM_NUMBER = gql`
    query ($identity: String!) {
        getByItemOrSerialNumber(identity: $identity) {
            itemId
            inventoryId
            itemNumber
            shelf
            mfgr
            serialNumber
            quantity
            warehouse
            so
        }
  }
`;

const GET_BY_SO = gql`
    query ($soId: String!) {
        getBySoId(soId: $soId) {
            itemId
            inventoryId
            itemNumber
            shelf
            mfgr
            serialNumber
            quantity
            warehouse
            so
        }
    }
`;

const GET_INV_STATUS = (code) => {
    switch (code) {
        case SO_PART:
            return "PART OF SO";
        case MISSING:
            return "MISSING";
        case REDUNDANT:
            return "REDUNDANT";
        case NOT_FOUND:
            return "UNKNOWN";
    }
};

const INV_WITH_SERIAL = "INV_WITH_SERIAL";
const INV_WITHOUT_SERIAL = "INV_WITHOUT_SERIAL";

const IS_NOT_BLANK = (str) => str && str.trim().length;
const IS_BLANK = (str) => !IS_NOT_BLANK(str);

const GROUP_INVENTORIES = (inventories, identifier) => {
    if (!inventories || !inventories.length) {
        return null;
    }

    const itemId = inventories[0].itemId;
    const itemNumber = inventories[0].itemNumber;
    const mfgr = inventories[0].mfgr;
    const invWithoutSerialNumbers = inventories.filter(inv => IS_BLANK(inv.serialNumber));
    let invsWithoutSerialGroupByShelf = {};

    invWithoutSerialNumbers.forEach(invWSN => {
        if (!invsWithoutSerialGroupByShelf[invWSN.so]) {
            invsWithoutSerialGroupByShelf[invWSN.so] = [invWSN];
        } else {
            invsWithoutSerialGroupByShelf[invWSN.so] = [...invsWithoutSerialGroupByShelf[invWSN.so], invWSN];
        }
    });

    return [
        ...inventories.filter(inv => IS_NOT_BLANK(inv.serialNumber)).map(inv => ({
            ...inv,
            key: inv.serialNumber,
            type: INV_WITH_SERIAL,
            identifier,
        })),
        ...Object.keys(invsWithoutSerialGroupByShelf).map(so => ({
            key: so + itemNumber,
            so,
            itemId,
            itemNumber,
            mfgr,
            quantity: 1,
            inventories: invsWithoutSerialGroupByShelf[so],
            type: INV_WITHOUT_SERIAL,
            identifier,
        }))
    ];
};

const COUNT_INVENTORIES = (inventories) => inventories
    .reduce(
        (sum, inv) => sum + (
            inv.type === INV_WITH_SERIAL || inv.status === NOT_FOUND ?
                1 :
                inv.inventories.length
        ),
        0,
    );

const RECONCILE = (invsBySo, invs) => {
    const invsKeys = invs.map(it => it.key);
    const invsBySoGrouped = GROUP_INVENTORIES(invsBySo);
    const invsBySoGroupedKeys = invsBySoGrouped.map(it => it.key);
    return [
        ...invs.map(it => {
            if (!it.itemNumber) {
                return {...it, status: NOT_FOUND}
            }
            if (invsBySoGroupedKeys.includes(it.key)) {
                return {...it, status: SO_PART}
            }
            return {...it, status: REDUNDANT};
        }),
        ...invsBySoGrouped.filter(it => !invsKeys.includes(it.key))
            .map(it => ({...it, status: MISSING}))
    ];
};

const DrawerContent = ({inventories}) => {
    if (!inventories) {
        return null;
    }
    const columns = [
        {
            title: 'Item Number',
            dataIndex: 'itemNumber',
            key: 'itemNumber',
        },
        {
            title: 'Quantity',
            key: 'quantity',
            render: ({inventories = []}) => inventories.length || 1,
        },
        {
            title: 'Serial Number',
            dataIndex: 'serialNumber',
            key: 'serialNumber',
        },
        {
            title: 'Mfgr',
            dataIndex: 'mfgr',
            key: 'mfgr',
        },
        {
            title: 'Shelf #',
            dataIndex: 'shelf',
            key: 'shelf',
        }
    ];
    return (
        <div className="mb-2" style={{maxHeight: 400, overflowX: "auto"}}>
            <Table
                className="mb-1"
                columns={columns}
                dataSource={inventories}
                pagination={false}
                rowKey={"key"}
                bordered
                style={{height: "100%"}}
            />
        </div>
    );
};

const ResultTitle = ({loading, inventories, soId}) => {

    let [drawerTitle, setDrawerTitle] = useState(null);
    let [chosenInventories, setChosenInventories] = useState(null);

    const partOfSo = inventories.filter(it => it.status === SO_PART);
    const redundant = inventories.filter(it => it.status === REDUNDANT);
    const missing = inventories.filter(it => it.status === MISSING);
    const scanned = inventories.filter(it => it.status !== MISSING);

    const partOfSoCount = COUNT_INVENTORIES(partOfSo);
    const redundantCount = COUNT_INVENTORIES(redundant);
    const missingCount = COUNT_INVENTORIES(missing);
    const scannedCount = COUNT_INVENTORIES(scanned);

    const openDrawer = (modalType) => () => {
        let inventories = [];
        switch (modalType) {
            case SO_PART:
                setDrawerTitle(`Items that are Part of SO (${partOfSoCount} items)`);
                inventories = partOfSo;
                break;
            case MISSING:
                setDrawerTitle(`Unscanned Items (${missingCount} items)`);
                inventories = missing;
                break;
            case REDUNDANT:
                setDrawerTitle(`List of Redundant Items (${redundantCount} items)`);
                inventories = redundant;
                break;
            case SCANNED:
                setDrawerTitle(`Total scanned Items (${scannedCount} items)`);
                inventories = scanned;
                break;
        }
        if (inventories.length) {
            setChosenInventories(inventories);
        }
    };

    const closeDrawer = () => {
        setDrawerTitle(null);
        setChosenInventories(null);
    };

    return (
        <div aria-disabled={loading}>
            <div className="so-cycle-result">
                <p
                    className="total"
                    onClick={openDrawer(SCANNED)}
                >{scannedCount}</p>
                Items have been scanned for
                <p
                    className="order"
                    onClick={openDrawer(SO_PART)}
                >SO #{soId}</p> ...
                <p
                    className="missing"
                    onClick={openDrawer(MISSING)}
                >{missingCount}</p> items have not been scanned &
                <p
                    className="redundant"
                    onClick={openDrawer(REDUNDANT)}
                >{redundantCount}</p> items are duplicates.
            </div>
            <Drawer
                title={drawerTitle}
                visible={chosenInventories}
                onClose={closeDrawer}
                placement="bottom"
                height={500}
            >
                <DrawerContent inventories={chosenInventories}/>
            </Drawer>
        </div>
    );
};

const Inventory = ({inventory}) => {
    if (inventory.serialNumber) {
        return (
            <div className="inventory-title">
                SN: {inventory.serialNumber}
            </div>
        );
    }
    if (inventory.itemNumber) {
        return (
            <div className="inventory-title">
                IQ: {inventory.itemNumber}
                {inventory.mfgr ? `, MFGR: ${inventory.mfgr}` : ""}
                {inventory.inventories ? ", QTY: " + inventory.inventories.length : null}
            </div>
        )
    }
    return (
        <div className="inventory-title">
            SN/IQ label: {inventory.key}
        </div>
    )
};

const InventoryList = ({inventories, removeInventory}) => inventories && inventories.length > 0 ?
    (
        <List
            className="inventories"
            itemLayout="vertical"
            size="large"
            grid={{
                gutter: 40,
                xs: 1,
                sm: 1,
                md: 1,
                lg: 2,
                xl: 2,
                xxl: 2,
            }}
            dataSource={inventories}
            renderItem={inventory => (
                <List.Item
                    style={{display: 'flex'}}
                    className={inventory.status}
                    extra={
                        <Button
                            icon="minus"
                            title="Remove"
                            type="danger"
                            className={inventory.status === 'missing' ? 'disabled' : ''}
                            onClick={removeInventory(inventory.key)}
                            disabled={inventory.status === 'missing'}
                        />
                    }>
                    <Inventory inventory={inventory}/>
                    <span className="inventory-status">
                        {GET_INV_STATUS(inventory.status)}
                    </span>
                </List.Item>
            )}
        >
        </List>
    ) : (
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE}/>
    )
;

const Content = ({client}) => {
    let [inventories, setInventories] = useState([]);
    let [identity, setIdentity] = useState('');
    let [loading, setLoading] = useState(false);
    let [soId, setSoId] = useState("");
    let [inventoriesBySo, setInventoriesBySo] = useState([]);

    const onSoIdChanged = (event) => setSoId(event.target.value);
    const clearInventories = () => {
        setInventories([]);
        setSoId("");
    };

    const identityRef = useRef(null);

    useEffect(() => {
        identityRef.current.focus()
    }, []);

    const addInventories = async (identity) => {
        const inventory = inventories.find(it => it.serialNumber === identity || it.itemNumber === identity || it.key === identity);
        if (!inventory) {
            setLoading(true);
            try {
                const {data: {getByItemOrSerialNumber: foundInventory}} = await client.query({
                    query: GET_BY_SERIAL_OR_ITEM_NUMBER,
                    variables: {identity}
                });
                if (foundInventory && foundInventory.length) {
                    if (foundInventory.find(it => it.serialNumber && it.itemNumber === identity)) {
                        notification.error({
                            message: "It is not allowed to use Item Number, if Serial number for this item exists."
                        });
                    } else {
                        setInventories([...inventories, ...GROUP_INVENTORIES(foundInventory, identity)]);
                    }
                } else {
                    setInventories([...inventories, {key: identity, status: NOT_FOUND}])
                }
            } catch (error) {
                console.log(error);
                notification.error({message: "Inventories Not Found"})
            } finally {
                setLoading(false);
            }
        } else {
            if (inventoriesBySo.find(ibs => ibs.serialNumber === identity || ibs.itemNumber === identity)) {
                setInventories(inventories.map(it => {
                    if (it.serialNumber === identity || it.itemNumber === identity) {
                        return {...it, status: SO_PART};
                    }
                    return it;
                }));
            }
        }
        setIdentity('');
        identityRef.current.focus();
    };

    const removeInventory = (key) => () => setInventories(inventories.filter(it => it.key !== key));

    const findBySoIdAndReconcile = async () => {
        setLoading(true);
        try {
            const {data: {getBySoId}} = await client.query({
                query: GET_BY_SO,
                variables: {soId}
            });
            if (getBySoId && getBySoId.length) {
                setInventoriesBySo(getBySoId);
                setInventories(RECONCILE(getBySoId, inventories));
            } else {
                notification.error({message: `SO #${soId} is either empty or does not exist or expired`});
                setSoId("");
            }
        } catch (e) {
            notification.error({message: "System error"});
        } finally {
            setLoading(false);
        }
    };

    const onPasteIdentity = (e) => {
        e.preventDefault();
        const identity = e.clipboardData.getData('Text');
        if (IS_NOT_BLANK(identity)) {
            setIdentity(identity);
            addInventories(identity);
        }
    };

    const onIdentityChanged = (e) => setIdentity(e.target.value);

    const onInventoriesClear = (e) => {
        e.preventDefault();
        clearInventories();
    };

    const INVENTORY_DELETION = (
        <a href="#" onClick={onInventoriesClear}>
            Clear
        </a>
    );

    return (
        <Scoped css={css}>
            <div>
                <PageHeader
                    title="SO Cycle Count"
                    onBack={() => window.history.back()}
                />
                <Row className="so-cycle-form" gutter={16}>
                    <Col xs={24} sm={24} md={8} lg={6} xl={4} xxl={4}>
                        <Input
                            ref={identityRef}
                            placeholder="SN/IQ label"
                            size="large"
                            onPaste={onPasteIdentity}
                            value={identity}
                            onChange={onIdentityChanged}
                            disabled={loading}
                            onKeyPress={event => event.key === 'Enter' ? addInventories(identity) : null}
                            addonAfter={<Button
                                type="button"
                                className="ant-btn ant-input-search-button ant-btn-primary ant-btn-lg"
                                disabled={loading || IS_BLANK(identity)}
                                onClick={() => addInventories(identity)}
                            >+</Button>}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={6} xl={4} xxl={4}>
                        <Input
                            placeholder="SO number"
                            size="large"
                            disabled={loading}
                            value={soId}
                            onChange={onSoIdChanged}
                        />
                    </Col>
                    <Col xs={24} sm={24} md={8} lg={6} xl={4} xxl={4}>
                        <Button
                            type="default"
                            size="large"
                            block
                            className="reconcile"
                            htmlType="submit"
                            disabled={loading || IS_BLANK(soId)}
                            onClick={findBySoIdAndReconcile}
                        >Reconcile</Button>
                    </Col>
                </Row>
                <ResultTitle
                    loading={loading}
                    inventories={inventories}
                    soId={soId}
                />
                <Row>
                    <Col xs={24} sm={24} md={24} lg={24} xl={19} xxl={16}>
                        <Spin spinning={loading} size={"large"}>
                            <Card
                                style={{maxWidth: 1025}}
                                title="Inventories"
                                extra={INVENTORY_DELETION}>
                                <InventoryList
                                    inventories={inventories}
                                    removeInventory={removeInventory}
                                />
                            </Card>
                        </Spin>
                    </Col>
                </Row>
            </div>
        </Scoped>
    );
};


const SOCycleCount = ({token, logout}) => (
    <Content
        client={new ApolloClient({
            uri: REACT_APP_SO_CYCLE_COUNT_API,
            headers: {"Authorization": `Bearer ${token}`},
            onError: ({graphQLErrors}) => {
                if (
                    graphQLErrors &&
                    graphQLErrors.find(error => error.extensions.code === 'UNAUTHENTICATED')
                ) {
                    logout();
                }
            }
        })}
    />
);

const css = `
    & .ant-page-header {
        display: flex;
        align-items: center;
        background: inherit;
    }
    & .ant-page-header-heading-title {
        font-size: 20px;
        color: #f65729;
    }
    & .ant-page-header, .so-cycle-form {
        padding: 16px 0;
    }
    & input[class*="ant-input"]:focus {
        box-shadow: none !important;
    }
    & .so-cycle-form div:not(:last-child) {
        margin-bottom: 8px;
    }
    & .so-cycle-form input+.ant-input-group-addon {
        padding: 0;
        border: 0;
    }
    & .so-cycle-form input+.ant-input-group-addon button {
        width: 100%;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0;
        font-weight: bold;
    }
    & .so-cycle-result {
        font-size: 16px;
        line-height: 2.2;
        display: flex;
        align-items: center;
        flex-wrap: wrap;
        margin-bottom: 24px;
    }
    & .so-cycle-result p {
        line-height: 1.5;
        margin: 0 5px;
        border-radius: 4px;
        font-weight: bold;
        text-decoration: underline;
    }
    & .so-cycle-result p {
        padding: 2px 10px;
    }
    & .so-cycle-result p:hover {
        text-decoration: none;
        color: #ffffff;
    }
    & .order {  
        border: 1px solid #1890ff !important;
    }
    & p.order:hover {
        background: #1890ff;
    }
    & p.total {
        border: 1px solid #d9d9d9 !important;
    }
    & p.total:hover {
        background: #d9d9d9;
    }
    & p.redundant, p.missing, p.order, p.total {
        cursor: pointer;
    }
    & p.redundant, .redundant .ant-list-item-main {
        border: 1px solid #ff4d4f !important;
    }  
    & p.redundant:hover {
        background: #ff4d4f;
    }
    & p.missing, .missing .ant-list-item-main {
        border: 1px dashed #1890ff !important;
    }
    & p.missing:hover {
        background: #1890ff;
    }
    & p.so-part, .so-part .ant-list-item-main {
        border-color: #1890ff !important;
    }
    & p.so-part:hover {
        background: #1890ff;
    }
    & .ant-card-head-title {
        color: rgba(0, 0, 0, 0.65);
    }
    & .ant-input-search-enter-button input + .ant-input-group-addon .ant-input-search-button {
        font-weight: bold;
    }
    @media (max-width: 767px) {
        & .reconcile {
            margin-top: 16px;
            margin-bottom: 8px;
        }
    }
    & .ant-list-vertical .ant-list-item-extra {
        margin: auto 0 auto 10px;
    }
    & .ant-list-item-main {
        min-width: auto;
        border: 1px solid #d9d9d9;
        border-radius: 4px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 11px;
    }
    @media (min-width: 1200px) {
        & .ant-list-item {
            max-width: 500px;
        }
    }
    & .ant-btn.disabled {
        visibility: hidden;
    }
    & .inventory-title {
        text-overflow: ellipsis;
        overflow: hidden;
        max-height: 21px;
        -webkit-line-clamp: 1;
        -webkit-box-orient: vertical;
    }
    & .inventory-status {
        font-size: 10px;
        color: #d9d9d9;
        margin-left: 10px;
    }
`;

export default SOCycleCount;
